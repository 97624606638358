html,
body {
}

.vjs-picture-in-picture-control,
.vjs-control-bar {
  display: none !important;
}
.video-js .vjs-fullscreen-control {
  display: none !important;
}
.vjs-control-bar {
  z-index: 50 !important;
  height: 55px !important;
}
.video-js .vjs-progress-control {
  height: 50% !important;
  margin-top: 1.5px;
}

.video-js button {
  outline: none !important;
}

.video_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inner_wrapper {
  position: relative;
  width: 66%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
.inner_wrapper.fullscreen {
  width: 100%;
}

.show_commands_trigger {
  width: 66%;
  height: 55px;
  z-index: 20;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 164px;
  transition: all 0.45s ease-in-out;
}
.show_commands_trigger:hover {
  z-index: -1;
}
.fullscreen_command_wrapper {
  width: 100%;
  opacity: 0;
  z-index: 30;
  min-height: 60px;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  bottom: 164px;
  transition: opacity 0.45s ease-in-out;
}
.video_wrapper.fullscreen .fs_command_holder {
  background-color: transparent;
}
.video_wrapper.fullscreen .fullscreen_command_wrapper {
  background-color: rgba(112, 112, 112, 0.5);
  z-index: 12;
  bottom: calc(7.6% + 164px);
}
.video_wrapper.fullscreen .show_commands_trigger {
  bottom: calc(7.6% + 164px);
}
.fullscreen_command_wrapper.show {
  width: 100%;
  pointer-events: all;
  opacity: 1;
  transition: opacity 0.65s ease-in-out;
}
.fullscreen_command_wrapper .fs_command_holder:first-child {
  position: absolute;
  height: 60px;
  left: 0;
  justify-content: flex-end;
  align-items: center;
}
.fullscreen_command_wrapper .fs_command_holder:last-child {
  position: absolute;
  height: 60px;
  right: 0;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.fullscree_icon_wrapper {
  padding: 0 10px;
  padding-top: 2px;
  display: flex;
  align-items: baseline;
}
.fullscree_icon_wrapper span {
}
.fs_command_holder {
  width: 17%;
  display: flex;
  background-color: rgba(112, 112, 112, 0.5);
  /*min-width: calc((1920px / 100) * 15);*/
  /*width: calc((100% / 100) * 17);*/
}
.fs_command_holder span {
  font-size: 17.5px;
  font-weight: bold;
  font-family: 'Roboto';
  color: #edeeef;
  padding: 0 10px;
}
.fs_command_holder svg {
  flex-shrink: 0;
  transition: all 0.2s ease-in-out;
}

.channels_wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 50;
  top: 0;
  left: 4.6%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  width: 148px;
  background-color: rgba(112, 112, 112, 0.5);
}

.active_channel_button {
  width: 148px;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  height: 155px;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.active_channel_overlay {
  background: #0000001d;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.channel_button_wrapper {
  margin-bottom: 15px;
}
.channel_button_wrapper.fullscreen {
  padding-top: 5px;
}
.channel_button_wrapper_hidden {
  display: none;
}

.open_channels_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin-top: 10px; */
  padding-bottom: 10px;
  padding-top: 10px;
  cursor: pointer;
}

.video-js {
  background-color: black;
  display: flex;
  width: 100%;
  height: 100%;
}
.video-js.fullscreen {
  width: 100% !important;
}
.video-js.fullscreen .video-js {
  width: 100% !important;
}

.video-js .vjs-tech {
  position: relative !important;
  width: 100% !important;
}
.vjs-big-play-button {
  display: none !important;
}

.subtitles_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 164px;
  width: 100%;
  /* position: absolute; */
  z-index: 10;
}
.subtitles_container.fullscreen {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 164px;
  width: 100%;
  z-index: 10;
  bottom: 7.6%;
}

.subtitles_overlay {
  height: 100%;
  width: 66%;
  display: flex;
  background-color: rgba(37, 37, 37, 0.5);
  /* position: absolute; */
  z-index: 12;
  top: 0;
  left: 0;
}

.subtitles_overlay_center {
  height: 100%;
  width: 66%;
  display: flex;
  justify-content: center;
  background-color: rgba(37, 37, 37, 0.5);
  /* position: absolute; */
  z-index: 12;
  top: 0;
  left: 0;
}

.subtitles_commands_overlay {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 66%;
  opacity: 0;
  background-color: transparent;
  height: 100%;
  z-index: 11;
  /* margin-top: 1px; */
  transition: opacity 0.5s ease-in-out, background-color 0.5s ease-in-out,
    width 0.25s ease-in-out;
}
.subtitles_commands_overlay.show {
  width: 100%;
  opacity: 1;
  background-color: rgba(112, 112, 112, 0.5);
  pointer-events: all;
  transition: opacity 0.5s ease-in-out, background-color 0.5s ease-in-out,
    width 0.25s ease-in-out;
}

.commands_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 17%;
  justify-content: space-between;
}
.command_wrapper {
  font-size: 16px;
  font-weight: bold;
  font-family: 'Roboto';
  color: #edeeef;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
  height: 35px;
}
.command_wrapper svg {
  transform: scale(0.6);
  transition: all 0.2s ease-in-out;
  width: 48px;
  height: 48px;
}
.command_name {
  margin-left: 10px;
}

.subtitle_wrapper_fs {
  position: absolute;
  width: 50px;
  height: 50px;
  background: red;
  top: 0;
  left: 0;
}

.main_container {
  font-family: 'Roboto';
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: #f7f7f7;
  z-index: 1;
}

.main_container:before {
  content: '';
  z-index: -1;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  width: 56.8%;
  height: 56.8vw;
  filter: blur(5px);
  background: url('../Icons/logo.svg') no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-file + label {
  cursor: pointer;
}

.input {
  background: none;
  padding: 5px;
  font: inherit;
  cursor: pointer;
  margin-bottom: 1%;
}

.bottom_info {
  font-family: 'Roboto';
  position: absolute;
  bottom: 22px;
  right: 22px;
  z-index: 30;
}
.bottom_info p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #000000;
  text-align: right;
}
.bottom_info h3 {
  margin: 0;
  padding: 0;
  font-size: 54px;
  color: #707070;
}
.bottom_info.fullscreen {
  color: #7070707d;
}

.subtitles_holder {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 25px;
}

.subtitles_holder_center {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 15px;
}

/*.subtitles_holder .subtitle:first-child {
 opacity: 0.6;
}
.subtitles_holder .subtitle:last-child {
  text-shadow: 1px 1px 8px rgba(0,0,0,0.5)
}*/

.subtitle {
  color: #edeeef;
  padding: 5px;
  /* text-transform: uppercase; */
  user-select: none;
  white-space: nowrap;
  margin-bottom: 1px;
  font-family: 'Roboto';
  font-weight: bold;
}

.loader {
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top: 0.2em solid #3374eb;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 0.6s linear infinite;
}

.live_text {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 0 !important;
}

.settings_button_container {
  flex: 1;
  position: relative;
  height: 100%;
  width: 100%;
}

.settings_button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 18px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(237, 238, 239);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.m3u8-title {
  color: black;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Roboto';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.m3u8-confirm-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.m3u8-confirm-button {
  color: #707070;
  padding: 0;
  margin: 0;
  background: none;
  font-family: 'Roboto';
  font-size: 14px;
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1597px) {
  .fullscree_icon_wrapper {
    padding-top: 0px;
    align-items: center;
  }
}

@media screen and (max-width: 1162px) {
  .fs_command_holder span {
    font-size: 12px;
  }
  .fullscree_icon_wrapper svg {
    width: 25px !important;
    height: 25px !important;
  }
  .subtitle {
    font-size: 22px !important;
  }
  .fullscree_icon_wrapper {
    position: absolute;
    bottom: 10px;
  }
}

@media screen and (max-width: 973px) {
  .bottom_info h3 {
    font-size: 45px;
  }
}

@media screen and (max-width: 843px) {
  .bottom_info h3 {
    font-size: 38px;
  }
}

@media screen and (max-width: 825px) {
  .subtitle {
    font-size: 18px !important;
  }
  .fs_command_holder span {
    font-size: 10px;
  }
}

@media screen and (max-width: 742px) {
  .bottom_info h3 {
    font-size: 25px;
    text-align: right;
  }
}

@media screen and (max-width: 730px) {
  .fs_command_holder span {
    font-size: 8px;
  }
  .subtitle {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 638px) {
  .fullscree_icon_wrapper {
    flex-wrap: wrap;
    bottom: auto;
  }
  .fs_command_holder span {
    padding: 0 !important;
    margin-top: 2px;
  }
}
